<template>
	<Offer>
		<template #banner>
			<img slot="banner" v-if="mobile === false" src="../../../../../assets/images/banners/personal.png" alt="banner">
			<img slot="banner" v-else src="../../../../../assets/images/mobile/personal.png" alt="banner">
		</template>

		<template #button>
			<i v-if="mobile === true" class="fas fa-arrow-left"></i>
			<router-link slot="button" to="/oferta">Wróć do spisu usług</router-link>
		</template>

		<template #small-title>
			<h4>PRAWO GOSPODARCZE</h4>
		</template>

		<template #title>
			<h2>Dane osobowe</h2>
		</template>

		<template #text>
			<p>Oferujemy pełną i bieżącą obsługę zmierzającą do zapewnienia przestrzegania przez Klienta procedur
				związanych z ochroną danych przetwarzanych w systemach informatycznych, w szczególności ochroną danych
				osobowych uwzględniających aktualne normy prawne. Jest to niezbędne działanie, w związku ze zmianami,
				jakie zachodzą w naszym społeczeństwie, ciągły rozwój, szybko rosnące ryzyko związane z przetwarzaniem
				danych, czy globalizacja biznesu.
			</p>
			<p>

				Nasze merytoryczne wsparcie pozwala naszym Klientom poznać, zrozumieć, a następnie wdrożyć procedury
				zmierzające do pełnej ochrony przetwarzanych danych.</p>
		</template>

		<template #range>
			<h3>Zakres:</h3>
		</template>
		<template #list>
			<ul class="offer__list">
				<li class="offer__list-item">Ochrona danych osobowych</li>
				<li class="offer__list-item">Pełne wsparcie przy wdrożeniu RODO</li>
				<li class="offer__list-item">Audyt zgodności z RODO</li>
				<li class="offer__list-item">Reprezentacja w postępowaniu przed UODO</li>
				<li class="offer__list-item">Doradztwo oraz szkolenia</li>
			</ul>
		</template>
	</Offer>
</template>

<script>
import Offer from '../Offer'
import {handleWith} from "@/mixins/globalMixins";

export default {
	components: {
		Offer
	},
	name: 'personal data',
	mixins: [handleWith],
}
</script>
